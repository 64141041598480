/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-top: 60px;
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  min-height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
  padding: 60px 15px 0;
  margin-top: 100px !important;
  margin-bottom: 105px !important;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}

.my-logo-banner {
  height: 40px;
  padding-right: 15px;
}

/*Pour afficher asterisk aux champs obligatoires des forms*/
label.required:before {
  content: "*";
}

/*Pour masquer certains éléments sur écran mobile (du hear et footer entre autres)  */
.content-desktop {
  display: block;
}
.content-mobile {
  display: none;
}

.custom-bg-overlay {
  background: linear-gradient(
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6),
      rgba(118, 62, 228, 0.2)
    ),
    url("../../img/restaurateur.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .content-desktop {
    display: none;
  }
  .content-mobile {
    display: block;
  }
}

/*Pour afficher les cards selon la taille de l'écran*/
.card-columns {
  @media screen and (min-width: 576px) {
    column-count: 1;
  }
  @include media-breakpoint-only(md) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 3;
  }
}

/*Pour masquer le bouton browse du input "custom file upload" et affciher le nom du fichier*/
.custom-file-label {
  opacity: 0 !important;
}
.custom-file-input {
  opacity: 1 !important;
}
